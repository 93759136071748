<template>
<div>
  <div class="post-content">

    <img src="../assets/home.png" height="576" width="993"/>
    <p style="margin-top:10px">
      <b>Herzlich willkommen im Christiani Lernportal!</b>
<br><br>
      Bitte melden Sie sich über den Anmeldebutton oben rechts zum Lernportal an. Falls Sie noch keine Zugangsdaten haben, wenden Sie sich bitte an info@christiani.de.
      <br>
      Im Lernportal erwarten Sie Ihre persönliche Lernumgebung für den von Ihnen gebuchten Lehrgang und viele Demo-Kurse, mit denen Sie unsere Angebote testen können. Alle Lernmedien zur Aus- und Weiterbildung können Sie direkt über den Christiani-Webshop buchen: christiani.de/digitales-lernen.
      <br>
      Gerne beraten wir Sie persönlich unter der Telefonnummer 07531/5801-100.
      <br>
      Wir wünschen Ihnen viel Erfolg beim Lernen mit Christiani.
    </p>
    </div>
</div>
</template>

<script>

export default {
  name: "Home",
  components: {}
}
</script>

<style scoped>

</style>
